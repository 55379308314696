import { useAvailableTimestamps } from '@cannect/hooks/useAvailableTimestamps'
import { Button, Typography } from '@cannect/new-components/atoms'
import { formatDate } from '@cannect/utils/date'
import { addDays, isSameDay } from 'date-fns'
import { motion, PanInfo } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

type WeekCalendarProps = {
  onSelectDate?: (date: Date) => void
  timestamps?: number[]
  selectedDateTime?: Date
}

const ANIMATION_DURATION = 0.2

function renderTimeSlot(
  date: Date,
  timeSlot: { value: string; label: string },
  selectedDateTime: Date | undefined,
  onSelect: (date: Date, time: string) => void
) {
  const timeDate = new Date(date)
  const [hours, minutes] = timeSlot.value.split(':')
  timeDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0)
  const isTimeSelected = selectedDateTime && selectedDateTime.getTime() === timeDate.getTime()

  return (
    <motion.div
      key={`${date.toISOString()}-${timeSlot.value}`}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      layout
      transition={{ duration: 0.2 }}>
      <Button
        variant={!isTimeSelected ? 'gray_light' : 'success_light'}
        onClick={() => onSelect(date, timeSlot.value)}
        className={`h-8 w-full rounded-lg p-1 text-base font-normal md:h-10 md:w-24 md:p-2 ${
          isTimeSelected ? '' : 'bg-white text-primary'
        }`}>
        {timeSlot.label}
      </Button>
    </motion.div>
  )
}

export const WeekCalendar = ({ onSelectDate, timestamps = [], selectedDateTime }: WeekCalendarProps) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { isDateDisabled, getAvailableTimesForDate } = useAvailableTimestamps(timestamps)
  const [direction, setDirection] = useState<'prev' | 'next' | null>(null)
  const [isNavigating, setIsNavigating] = useState(false)
  const [selectedDayDate, setSelectedDayDate] = useState<Date | null>(null)

  const findAvailableDate = (startDate: Date): Date | null => {
    let currentDate = new Date(startDate)
    currentDate.setHours(0, 0, 0, 0)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 365; i++) {
      if (!isDateDisabled(currentDate) && getAvailableTimesForDate(currentDate).length > 0) {
        return currentDate
      }
      currentDate = addDays(currentDate, 1)
    }
    return null
  }

  const daysToShow = 5

  const dates = useMemo(() => {
    if (!selectedDayDate) return []

    const currentDates: Date[] = []
    let dayIndex = 0
    let currentDate = selectedDayDate

    while (currentDates.length < daysToShow && dayIndex < 365) {
      if (!isDateDisabled(currentDate) && getAvailableTimesForDate(currentDate).length > 0) {
        currentDates.push(currentDate)
      }
      currentDate = addDays(currentDate, 1)
      // eslint-disable-next-line no-plusplus
      dayIndex++
    }

    return currentDates
  }, [selectedDayDate, daysToShow, isDateDisabled, getAvailableTimesForDate])

  const findNextAvailableDate = (date: Date, direction: 'prev' | 'next') => {
    const step = direction === 'prev' ? -1 : 1
    let nextDate = addDays(date, step)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 365; i++) {
      if (!isDateDisabled(nextDate) && getAvailableTimesForDate(nextDate).length > 0) {
        return nextDate
      }
      nextDate = addDays(nextDate, step)
    }
    return date
  }

  const hasNextDates = useMemo(() => {
    if (!selectedDayDate || dates.length < daysToShow) return false
    const nextDate = findNextAvailableDate(selectedDayDate, 'next')
    return !isSameDay(nextDate, selectedDayDate)
  }, [selectedDayDate, dates.length, daysToShow])

  const hasPrevDates = useMemo(() => {
    if (!selectedDayDate) return false
    const prevDate = findNextAvailableDate(selectedDayDate, 'prev')
    return !isSameDay(prevDate, selectedDayDate)
  }, [selectedDayDate])

  const handleDateSelect = (date: Date, time: string) => {
    if (!isDateDisabled(date)) {
      const dateStr = formatDate(date, 'yyyy-MM-dd')
      onSelectDate?.(new Date(`${dateStr}T${time}:00.000-03:00`))

      const isDateVisible = dates.some((d) => isSameDay(d, date))
      if (!isDateVisible) {
        setSelectedDayDate(date)
      }
    }
  }

  const handleNavigate = async (dir: 'prev' | 'next') => {
    if (!selectedDayDate || isNavigating) return

    setIsNavigating(true)
    setDirection(dir)

    const nextDate = findNextAvailableDate(selectedDayDate, dir)
    if (nextDate && !isSameDay(nextDate, selectedDayDate)) {
      setSelectedDayDate(nextDate)
      const dateStr = formatDate(nextDate, 'yyyy-MM-dd')
      const firstAvailableTime = getAvailableTimesForDate(nextDate)[0]
      if (firstAvailableTime) {
        onSelectDate?.(new Date(`${dateStr}T${firstAvailableTime.value}:00.000-03:00`))
      }
    }

    await new Promise((resolve) => {
      setTimeout(resolve, ANIMATION_DURATION * 1000)
    })
    setIsNavigating(false)
  }

  const handleDragEnd = async (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (Math.abs(info.offset.x) > 50) {
      const direction = info.offset.x > 0 ? 'prev' : 'next'
      if (direction === 'next' && dates.length < daysToShow) return
      const canNavigate = direction === 'prev' ? hasPrevDates : hasNextDates

      if (canNavigate) {
        handleNavigate(direction)
      }
    }
  }

  useEffect(() => {
    const searchDate = params.get('searchDate')
    const startDate = searchDate ? new Date(`${searchDate}T00:00:00-03:00`) : new Date()
    const availableDate = findAvailableDate(startDate)

    if (availableDate) {
      setSelectedDayDate(availableDate)
      const firstTime = getAvailableTimesForDate(availableDate)[0]
      if (firstTime) {
        const dateStr = formatDate(availableDate, 'yyyy-MM-dd')
        onSelectDate?.(new Date(`${dateStr}T${firstTime.value}:00.000-03:00`))
      }
    }
  }, [location.search, timestamps])

  if (!selectedDayDate) return null

  return (
    <div className="relative flex w-full items-start">
      <motion.div
        whileHover={{ scale: hasPrevDates ? 1.1 : 1 }}
        whileTap={{ scale: hasPrevDates ? 0.9 : 1 }}
        className="hidden py-2 md:block">
        <Button
          unstyled
          onClick={() => hasPrevDates && handleNavigate('prev')}
          className={`p-2 ${!hasPrevDates ? 'cursor-not-allowed opacity-30' : ''}`}
          disabled={!hasPrevDates}>
          <ChevronLeft className="h-6 w-6 text-gray-400" />
        </Button>
      </motion.div>

      <motion.div
        className="w-full overflow-hidden"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.2}
        dragTransition={{ bounceStiffness: 400, bounceDamping: 15 }}
        onDragEnd={handleDragEnd}>
        <motion.div
          className="grid grid-cols-5 gap-2 p-2"
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          variants={slideVariants}
          initial="center"
          animate="center"
          exit="exit"
          custom={direction}
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}>
          {dates.map((date) => {
            const availableTimes = getAvailableTimesForDate(date)
            const isSelectedDateTime = selectedDateTime && isSameDay(selectedDateTime, date)
            const maxSlots = Math.max(5, ...dates.map((d) => getAvailableTimesForDate(d).length))
            const placeholdersNeeded = maxSlots - availableTimes.length

            return (
              <motion.div key={date.toISOString()} layout className="flex flex-col gap-2">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                  className={`flex h-16 w-full select-none flex-col items-center justify-center rounded-lg bg-white shadow-sm md:h-20 md:w-24 ${
                    isSelectedDateTime ? 'ring-2 ring-success-100' : ''
                  }`}>
                  <Typography.Text type="captionOne" className="uppercase text-secondary-500 md:text-base">
                    {formatDate(date, 'EEEEEE')}
                  </Typography.Text>
                  <Typography.Text className="-mt-1 font-semibold text-primary md:-mt-2 md:text-xl">
                    {formatDate(date, 'dd')}
                  </Typography.Text>
                  <Typography.Text type="captionOne" className="-mt-1 font-light text-primary md:-mt-3 md:text-base">
                    {formatDate(date, 'MMM')}
                  </Typography.Text>
                </motion.div>

                <div className="flex flex-col gap-2">
                  {availableTimes.map((timeSlot) => renderTimeSlot(date, timeSlot, selectedDateTime, handleDateSelect))}
                  {Array.from({ length: placeholdersNeeded }).map((_, index) => (
                    <div
                      key={`placeholder-${index}`}
                      className="h-8 w-full rounded-lg bg-white p-1 opacity-50 shadow-sm md:h-10 md:w-24 md:p-2"
                    />
                  ))}
                </div>
              </motion.div>
            )
          })}
        </motion.div>
      </motion.div>

      <motion.div
        whileHover={{ scale: hasNextDates ? 1.1 : 1 }}
        whileTap={{ scale: hasNextDates ? 0.9 : 1 }}
        className="hidden py-2 md:block">
        <Button
          unstyled
          onClick={() => hasNextDates && handleNavigate('next')}
          className={`p-2 ${!hasNextDates ? 'cursor-not-allowed opacity-30' : ''}`}
          disabled={!hasNextDates}>
          <ChevronRight className="h-6 w-6 text-gray-400" />
        </Button>
      </motion.div>
    </div>
  )
}

const slideVariants = {
  enter: (direction: 'prev' | 'next') => ({
    x: direction === 'prev' ? -500 : 500,
    opacity: 0
  }),
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction: 'prev' | 'next') => ({
    x: direction === 'prev' ? 500 : -500,
    opacity: 0
  })
}
