import { z } from 'zod'

const emptyFieldMessage = 'Preencha este campo'

export const validateCep = /^\d{5}-?\d{3}$/

export const CreditCardSchema = z.object({
  card_number: z.string().min(16, emptyFieldMessage),
  card_name: z.string().min(3, emptyFieldMessage),
  card_cvv: z.string().min(3, emptyFieldMessage),
  installments: z.number().min(1, emptyFieldMessage)
})

export type CreditCardSchemaType = z.infer<typeof CreditCardSchema>
