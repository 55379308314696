import { IPrescriberInfo } from 'hooks/useScheduling/types'

import { formatPhone, formatZipCode, formatReal, formatCpf, dateMask } from 'utils/formatters'
import { FilterBy } from './FormSteps/FilterByStep/FilterForm'
import { TSchedulingPrescriberListItem } from '@cannect/services/resources/scheduling'

export type INPUT_FORMAT_TYPES =
  | 'money'
  | 'cep'
  | 'phone'
  | 'number'
  | 'credit-card'
  | 'text'
  | 'cpf'
  | 'card_valid_date'
  | 'password'
  | 'date'

export const returnVisitText = (prescriberInfo: TSchedulingPrescriberListItem) => {
  if (!prescriberInfo) return ''

  const { include_return_visit, return_visit_value, return_period } = prescriberInfo

  if (!include_return_visit) {
    return 'Retorno não incluso no valor da consulta.'
  }

  if (return_period && return_visit_value && return_visit_value !== '0') {
    return `Retorno incluso nos próximos ${return_period} dias, pelo valor de ${formatReal(return_visit_value)}.`
  }

  if (return_period && (!return_visit_value || return_visit_value === '0')) {
    return `Retorno incluso nos próximos ${return_period} dias, de forma gratuita.`
  }

  if (!return_period && return_visit_value && return_visit_value !== '0') {
    return `Retorno incluso pelo valor de ${formatReal(return_visit_value)}.`
  }

  return 'Retorno incluso de forma gratuita.'
}

export const formatAgeGroups = (ageGroups: { id: number; name: string }[]) => {
  if (!ageGroups || ageGroups.length === 0) return ''

  const cleanedAgeGroups = ageGroups.map((group) => group.name.replace(/ *\([^)]*\) */g, '').toLowerCase())

  const lastGroup = cleanedAgeGroups.pop()

  return cleanedAgeGroups.length ? `${cleanedAgeGroups.join(', ')} e ${lastGroup}` : lastGroup
}

export const calculateDifferenceBetweenValues = (value1?: number, value2?: number) => {
  if (!value1 || !value2) return null
  const difference = value1 - value2
  return difference.toFixed(2).replace('.', ',')
}
export const parseFiltersList = (list: any[], prefix: string) =>
  list.map((item) => ({ ...item, value: `${prefix}:${item.value}` }))

export const extractFilterType = (filter: string): FilterBy => {
  return filter ? (filter.split(':')[0] as FilterBy) : 'expertise'
}

function toBRL(value: number) {
  if (!value) return ''
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const formatInputValue = (value: string, type: INPUT_FORMAT_TYPES) => {
  if (type === 'money') {
    if (!Number.isNaN(value)) {
      return toBRL(parseFloat(value))
    }
    return 'R$ 0,00'
  }
  if (type === 'cpf') {
    return formatCpf(value)
  }
  if (type === 'date') {
    return dateMask(value, 'fullDate')
  }

  if (type === 'cep') {
    return formatZipCode(value)
  }
  if (type === 'phone') {
    return formatPhone(value)
  }
  if (type === 'number') {
    if (!Number.isNaN(value)) {
      return value
    }
    return ''
  }

  return value
}
