import styled from 'styled-components'

interface ButtonContainerProps {
  background: string
  isOutlined: boolean
  height: number
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  border: ${({ isOutlined, background }) => (isOutlined ? `1px solid ${background}` : '1px solid transparent')};
  background: ${({ background, isOutlined }) => (isOutlined ? 'transparent' : background)};
  color: ${({ isOutlined, background }) => (isOutlined ? background : '#ffffff')};
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  padding: 0 20.5px;
  border-radius: 8px;

  width: fit-content;
  transition: transform 0.3s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    transform: scale(1.01);
  }
`
