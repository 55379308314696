import { cn } from '@cannect/lib/utils'
import { Slot, Slottable } from '@radix-ui/react-slot'
import * as React from 'react'
import { ImSpinner2 } from 'react-icons/im'
import { tv, VariantProps } from 'tailwind-variants'

const buttonVariants = tv({
  base: 'inline-flex items-center justify-center gap-4 whitespace-nowrap rounded border-0 border-solid font-sans text-sm font-semibold ring-offset-primary-700 transition-opacity duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
  variants: {
    variant: {
      default: 'bg-primary text-white hover:bg-primary/90',
      primary_light: 'border-transparent bg-primary-300 text-primary-700 hover:bg-primary-300/90',
      gray_light: 'bg-gray-50 text-primary-800 hover:bg-gray-50/90',
      gray: 'bg-gray-700 text-white hover:bg-gray-700/90',
      outline: 'border-2 border-primary bg-transparent text-primary hover:opacity-80',
      outline_light: 'border-2 border-primary-700 bg-transparent text-primary-700 hover:opacity-80',
      outline_white: 'border-2 border-white bg-transparent text-white hover:opacity-80',
      success_light: 'bg-success-100 text-primary-1000 hover:bg-success-100/90'
    },
    size: {
      default: 'h-10 px-6',
      md: 'h-8 px-4',
      lg: 'h-[50px] px-6 text-base',
      xs: 'h-5 px-4 text-sm',
      icon: 'h-auto w-auto px-4 py-3'
    },
    full: {
      true: 'w-full'
    },
    unstyled: {
      true: 'h-auto bg-transparent px-0 text-inherit ring-0 hover:bg-transparent hover:opacity-80'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
  icon?: React.ReactElement
  iconPlacement?: 'left' | 'right'
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'default',
      size = 'default',
      full = false,
      unstyled = false,
      asChild = false,
      icon: Icon = null,
      iconPlacement = 'right',
      isLoading = false,
      type = 'button',
      disabled = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        type={type}
        className={cn(buttonVariants({ variant, size, className, full, unstyled }))}
        ref={ref}
        disabled={disabled || isLoading}
        {...props}>
        {!isLoading && Icon && iconPlacement === 'left' && React.cloneElement(Icon)}

        <Slottable>
          {isLoading ? <ImSpinner2 size={18} className="animate-spin text-inherit" /> : props.children}
        </Slottable>
        {!isLoading && Icon && iconPlacement === 'right' && React.cloneElement(Icon)}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
