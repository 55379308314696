/**
 * Get name and return first and last letters of name
 * Ex: John Doe => JD
 * Ex: John => J
 * @param name String
 * @returns String
 */

import { differenceInYears, isValid } from 'date-fns'

export function getLettersForName(name?: string) {
  if (!name) return ''

  const names = name.split(' ')

  if (names.length < 2) {
    return names.at(0)?.at(0)?.toUpperCase()
  }

  const firstLetter = names.at(0)?.at(0)?.toUpperCase()

  const lastLetter = names.at(-1)?.at(0)?.toUpperCase()

  return `${firstLetter}${lastLetter}`
}

/**
 * Get initial from name
 * Ex: John Doe => J
 * @param name String
 * @returns String
 */

export const getInitialLetterForName = (name?: string) => {
  if (!name) return '-'
  return name.split(' ')[0][0].toUpperCase()
}

/**
 * Calculates age in years from a birth date
 * @param birthDate - Birth date as string or Date object
 * @returns Formatted age string or undefined if invalid input
 * @example
 * calculateAge('1990-05-15') // "33 anos"
 * calculateAge('15/05/1990') // "33 anos"
 * calculateAge(new Date(1990, 4, 15)) // "33 anos"
 * calculateAge(null) // undefined
 */
export const calculateAge = (birthDate?: string | Date): string | undefined => {
  try {
    if (!birthDate) return undefined

    const birthdateObj = birthDate instanceof Date ? birthDate : new Date(birthDate)
    if (!isValid(birthdateObj)) return undefined

    const age = differenceInYears(new Date(), birthdateObj)
    return `${age} anos`
  } catch {
    return undefined
  }
}
