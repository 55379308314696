import { TabsPrimitive, Typography } from '@cannect/new-components/atoms'
import AccordionPartnes from 'new-components/partnesComponents/AccordionPartnes'
import { PatientDataFaq, ProfessionalFaq } from './constants'

export const CommonQuestionsSection = () => {
  return (
    <section className="container flex flex-col items-center py-section text-center">
      <div className="mb-[21px] flex flex-col items-center gap-[16px]">
        <Typography.Heading type="headingThree" className="leading-10 text-primary-700 lg:text-4xl">
          Perguntas frequentes
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="leading-6 text-secondary lg:text-lg">
          Tire todas as suas dúvidas sobre canabinóides e entenda como pode mudar a sua vida.
        </Typography.Text>
      </div>
      <TabsPrimitive.Root
        defaultValue="patients"
        className="min-w-[90vw] max-w-[90vw] md:min-w-[80vw] md:max-w-[80vw] lg:min-w-[70vw] lg:max-w-[70vw]">
        <TabsPrimitive.List className="mb-[32px] lg:mb-[24px]">
          <TabsPrimitive.Trigger
            className="m-[1px] rounded-md px-4 py-2 text-sm font-bold text-secondary-800 lg:my-[2px] lg:text-base"
            value="patients">
            Para pacientes
          </TabsPrimitive.Trigger>
          <TabsPrimitive.Trigger
            className="m-[1px] rounded-md px-4 py-2 text-sm font-bold text-secondary-800 lg:my-[2px] lg:text-base"
            value="prescribers">
            Para profissionais da saúde
          </TabsPrimitive.Trigger>
        </TabsPrimitive.List>
        <TabsPrimitive.Content value="patients">
          <AccordionPartnes data={PatientDataFaq.accordionItems} withPrefix={false} size="full" />
        </TabsPrimitive.Content>
        <TabsPrimitive.Content value="prescribers">
          <AccordionPartnes data={ProfessionalFaq.accordionItems} withPrefix={false} size="full" />
        </TabsPrimitive.Content>
      </TabsPrimitive.Root>
    </section>
  )
}
