/* eslint-disable react/no-unstable-nested-components */
import { cn } from '@cannect/lib/utils'
import { ptBR } from 'date-fns/locale'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { DayPicker, DayPickerProps } from 'react-day-picker'
import { buttonVariants } from '../Button'

export type CalendarProps = DayPickerProps

export const Calendar = ({
  classNames,
  showOutsideDays = true,
  fixedWeeks = true,
  captionLayout = 'dropdown-buttons',
  fromYear = 2010,
  toYear = new Date().getFullYear() + 4,
  selected,
  ...props
}: DayPickerProps) => {
  return (
    <DayPicker
      {...props}
      locale={ptBR}
      selected={selected}
      // @ts-expect-error lib
      defaultMonth={selected || new Date()}
      showOutsideDays={showOutsideDays}
      fixedWeeks={fixedWeeks}
      captionLayout={captionLayout}
      fromYear={fromYear}
      toYear={toYear}
      className="p-2"
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center px-10',
        caption_label: 'flex items-center gap-2 text-sm font-medium',
        caption_dropdowns: 'flex gap-4 items-center',
        dropdown_month: cn(buttonVariants({ variant: 'primary_light', size: 'md' }), 'relative'),
        dropdown_year: cn(buttonVariants({ variant: 'primary_light', size: 'md' }), 'relative'),
        dropdown: 'absolute text-lg inset-0 w-full appearance-none opacity-0 z-10 cursor-pointer',
        vhidden: 'hidden',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(buttonVariants({ unstyled: true }), 'opacity-50 hover:opacity-100'),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-2',
        head_row: 'flex gap-1',
        head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: cn(
          'text-center text-sm p-1 relative bg-transparent focus-within:z-20 [&:has([aria-selected].day-outside)]:bg-primary-800/50',
          props.mode === 'range'
            ? '[&:has([aria-selected])]:bg-primary-800 [&:has(.day-range-start)]:rounded-l-xl [&:has(.day-range-end)]:rounded-r-xl'
            : '[&:has([aria-selected])]:rounded-md'
        ),
        day: 'rounded-full h-8 w-8 p-0 font-normal aria-selected:opacity-100 hover:bg-muted flex items-center justify-center cursor-pointer border-transparent bg-inherit',
        day_selected: '!bg-primary-800 text-white hover:bg-primary-foreground font-semibold hover:text-primary-800',
        day_today: 'bg-background text-neutral-800',
        day_outside: 'text-muted hover:text-muted-foreground ',
        day_disabled: 'text-muted opacity-70 hover:text-muted-foreground',
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_range_middle: 'aria-selected:bg-transparent aria-selected:text-white',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={{
        IconLeft: () => <ChevronLeftIcon className="size-5" />,
        IconRight: () => <ChevronRightIcon className="size-5" />
      }}
    />
  )
}

Calendar.displayName = 'Calendar'
