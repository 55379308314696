import Banner from 'components/Banner'
import LoadingContainer from 'components/LoadingContainer'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useQueryString from 'hooks/useQueryString'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import useMediaQuery from '../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../utils/constants'
import { StyledCheckbox } from '../AppointmentScheduling/FormSteps/CheckoutStep/PaymentStep/styles'
import Step from '../NewCart/Steps'
import { Address } from './Address'
import { Customer } from './Customer'
import { Documents } from './NewDocuments/index'

import {
  BackCart,
  ButtonContainer,
  ButtonContinue,
  Container,
  ContainerResumePay,
  DocumentNotificationBox,
  TextDocument
} from './styles'

interface CustomerData {
  email: string
  cpf: string
  phone: string
  name: string
  birthday: string
  gender: 'male' | 'female' | 'other'
  phone_residencial?: string | undefined
}

interface AddressData {
  shippingAddress: any
  billingAddress: any
}

function NewCartDocuments() {
  const [isAllAddress, setIsAllAddress] = useState(false)
  const [isAllCustomerData, setIsAllCustomerData] = useState(false)
  const [error, setError] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [address, setAddress] = useState<AddressData>({
    shippingAddress: {},
    billingAddress: {}
  })
  const [customer, setCustomer] = useState<CustomerData>({})
  const { lastOrderCreated, loading: loadignOrdens, getLastOrder } = usePrepareCart()
  const [documentList, setDocumentList] = useState({} as any)
  const [confirmDialogDocument, setConfirmDialogDocument] = useState(false)
  const originCheck = ['acolhimento-cannect', 'B2C', 'COD B', 'Recipe', 'COD C']
  const isOriginMatched = originCheck.some((origin) => origin === lastOrderCreated.originCheckout)
  const { items } = lastOrderCreated
  const isAllSupplements = items.every((item: any) => item?.product?.class_id === 6 && item.product?.brand_id === 108)

  const atualURL = window.location.href
  const partUrl = atualURL.split('/')
  const cartWithautAuth = partUrl.pop()

  const isValidateSubmit =
    !isAllAddress ||
    !isAllCustomerData ||
    (!isAllSupplements && (isOriginMatched ? documentList?.RECEITA?.length === 0 : !confirmDialogDocument))

  const [loadingContainer, setLoading] = useState(false)
  const history = useHistory()
  const { filters } = useQueryString()
  const cart_id = filters?.cart_id
  console.log('cart_id', cart_id)
  const validUrl = cart_id ? lastOrderCreated.uuid : lastOrderCreated.id

  useEffect(() => {
    getLastOrder()
  }, [])

  useEffect(() => {
    setCustomer(lastOrderCreated?.person)
  }, [lastOrderCreated])

  const handleAddress = (type: 'shippingAddress' | 'billingAddress', data: any) => {
    setAddress({ ...address, [type]: data })
  }

  const sendAdressesData = async () => {
    const BASE_PAYMENT_URL = cart_id ? 'order_cart_public' : 'order_cart'
    const { documents, recipes } = lastOrderCreated
    const { shippingAddress, billingAddress } = address

    try {
      if (!isAllAddress) throw new Error('Por favor, preencha todos os campos obrigatórios')

      setLoading(true)
      const payload = {
        documents:
          !isAllSupplements &&
          (documents?.flat()?.length > 0 || documents.length > 0) &&
          (documents?.documents_order || documents),
        recipes: [recipes[recipes.length - 1]],
        shippingAddress,
        billingAddress,
        items,
        customer,
        person_id: cart_id && lastOrderCreated?.person?.id
      }
      const { data } = await api.put(`/${BASE_PAYMENT_URL}/${lastOrderCreated.id}`, payload)

      if (data?.success) {
        await api
          .post(`payments/order/${data?.order?.id}`)
          .then((response) => {
            if (response?.data?.success) {
              if (cartWithautAuth === 'documentos') {
                history.push(`/finalizar-compra/${data?.order?.id}?cart_id=${data?.order?.uuid}`)
              } else {
                history.push(`/finalizar-compra-wa/${data?.order?.id}?cart_id=${cart_id}`)
              }
            }
          })
          .catch((error) => {
            const errorMsg = error?.response?.data?.errors
              ? error?.response?.data?.errors
              : 'Erro ao finalizar a compra'
            setError(true)
            toast.error(errorMsg || 'Erro ao finalizar a compra')
            return { error: true }
          })
      }
    } catch (error) {
      setError(true)
      return { error: true }
    } finally {
      setLoading(false)
    }
  }

  if (loadignOrdens || loadingContainer) return <LoadingContainer loading />

  const backToCart = () => {
    const url = cart_id ? `/carrinho-wa?cart_id=${cart_id}` : `/carrinho`
    history.push(`${url}`)
  }

  return (
    <Container>
      <Step step={2} />
      {!isAllSupplements &&
        (isOriginMatched ? (
          <Documents orderIdObject={validUrl} returnDocs={setDocumentList} />
        ) : (
          <DocumentNotificationBox>
            <TextDocument>
              Eu declaro estar ciente de que, após efetuar o pagamento e a fim de permitir a continuidade do processo de
              importação dos produtos, assumo a responsabilidade por fornecer os seguintes documentos necessários,
              conforme exigido pela ANVISA (Agência Nacional de Vigilância Sanitária).
              <br />
              <br />
              Os documentos mencionados podem ser enviados na próxima tela:
              <br />
              <br />
              <ul>
                <li>Pedido/Receita médica válida (válida por 6 meses)</li>
                <li>Documento de identidade (RG ou CNH)</li>
                <li>Comprovante de residência</li>
                <li>Formulário ANVISA</li>
              </ul>
              <br />
              <StyledCheckbox onChange={(e) => setConfirmDialogDocument(e.target.checked)}>
                Li e compreendi a necessidade de envio dos documentos.
              </StyledCheckbox>
            </TextDocument>
          </DocumentNotificationBox>
        ))}

      <ContainerResumePay isMobile={isMobile}>
        <Container isMobile={isMobile}>
          <Address setIsAllAddress={setIsAllAddress} handleAddress={handleAddress} />
        </Container>
        <Container isMobile={isMobile}>
          <Customer customer={customer} setCostumer={setCustomer} setIsAllCustomerData={setIsAllCustomerData} />
        </Container>
      </ContainerResumePay>
      {isValidateSubmit && (
        <Banner title="Para Finalizar a compra por favor preencha os seguintes campos" variant="negative">
          <div>
            {!isAllAddress && <p>Dados de entrega</p>}
            {!isAllCustomerData && <p>Dados pessoais</p>}
            {!confirmDialogDocument && !isOriginMatched && !isAllSupplements && (
              <p>Por favor, confirme a leitura das informações sobre a documentação necessária</p>
            )}
            {documentList?.RECEITA?.length === 0 && isOriginMatched && (
              <p>É obrigatório anexar uma receita médica para continuar</p>
            )}
          </div>
        </Banner>
      )}
      <ButtonContainer>
        <BackCart onClick={backToCart}>voltar para o carrinho</BackCart>
        <ButtonContinue onClick={sendAdressesData} disabled={isValidateSubmit}>
          Continuar
        </ButtonContinue>
      </ButtonContainer>
    </Container>
  )
}

export default NewCartDocuments
