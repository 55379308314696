import { Checkbox } from 'antd'
import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

interface TextDetailProps {
  flexDirection?: 'column' | 'row'
}

export const Container = styled.div`
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'status status'
    'billing details'
    'billing resume'
    'payment conditions';

  gap: 56px;
  color: ${temporaryTheme.colors.darkGrey};
  font-family: 'Poppins', sans-serif;

  @media ${MOBILE_WIDTH} {
    grid-template-columns: 1fr;
    gap: 18px;
    grid-template-areas:
      'status'
      'details'
      'resume'
      'billing'
      'payment'
      'conditions';
  }
`

export const OptionInstallment = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  * {
    font-family: 'Poppins', sans-serif;
    color: rgba(61, 61, 61, 1);
  }

  .values_installment {
  }

  .not_installment {
    color: ${temporaryTheme.colors.linkHover};
  }
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 56px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: 16px;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  height: 78px;
  margin-bottom: 12px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 6px;
    height: auto;
  }
`

export const Box = styled.div`
  width: 100%;
  background-color: #f8f7fc;
  border-radius: 8px;
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media ${MOBILE_WIDTH} {
    padding: 20px;
    height: auto;
    min-height: 100px;
  }
`

export const BoxTitle = styled.h2`
  position: relative;
  font-size: 15px;
  font-weight: 600;
  color: #3d3d3d;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 20px;
  margin-bottom: 24px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -36px;
    right: -36px;
    height: 0.7px;
    background-color: #d1cece;
    @media ${MOBILE_WIDTH} {
      left: -25px;
      right: -25px;
    }
  }

  @media ${MOBILE_WIDTH} {
    font-size: 15px;
  }
`

export const TextDetail = styled.div<TextDetailProps>`
  ${({ flexDirection = 'row' }) => css`
    display: flex;
    gap: 6px;
    flex-direction: ${flexDirection};

    p {
      font-weight: 700;
    }
    span {
      font-weight: 400;
    }
    * {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
    }

    @media ${MOBILE_WIDTH} {
      * {
        font-size: 13px;
      }
    }
  `}
`

export const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  button {
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
  }
`

export const StatusTitle = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 26px;
  font-weight: 700;
`

export const AgreeFieldWrapper = styled.div`
  height: 36px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  span:first-child {
    font-size: 16px;
    line-height: 2px;
  }
  .error-agreement {
    margin-left: 24px;
  }
  span,
  a {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }

  @media ${MOBILE_WIDTH} {
    padding-bottom: 60px;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: #748351;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5d6941;
    border-color: #5d6941;
  }

  .ant-checkbox-checked::after {
    border-color: #fff;
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5d6941 !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked:focus .ant-checkbox-inner {
    background-color: #464f31 !important;
    border-color: #464f31 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #464f31 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #464f31 !important;
    border-color: #464f31 !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #5d6941 !important;
  }
`
