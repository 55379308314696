/* eslint-disable import/no-duplicates */
import { addDays, format, formatISO, parse, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { capitalizeFirstLetter } from './formatters'

/**
 * Format Data with locale ptBr
 * @link https://date-fns.org
 * @param date string
 * @param toFormat string Ex: 'yyyy-MM-dd' more examples in @link https://date-fns.org/v2.30.0/docs/format
 * @returns string
 */

export const formatDate = (date: Date | string, toFormat: string) => {
  if (!date || date === '') return ''

  const dateObj = typeof date === 'string' ? parseISO(date) : date
  return format(dateObj, toFormat, {
    locale: ptBR
  })
}

export const getEstimatedArrivalDates = (minDays: number, maxDays: number) => {
  const today = new Date()
  const startDate = addDays(today, minDays)
  const endDate = addDays(today, maxDays)

  const formatDate = (date: Date) => {
    return format(date, 'dd/MM', { locale: ptBR })
  }

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate)
  }
}

/**
 * Converts a date string from DD/MM/YYYY format to ISO string
 * @param dateString Format: DD/MM/YYYY (e.g., "02/11/1997")
 * @returns ISO date string (e.g., "1997-11-02T00:00:00.000Z")
 */
export const parseToISO = (dateString?: string): string => {
  if (!dateString) return ''
  const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date())
  return formatISO(parsedDate)
}
/**
 * Combines a Date object with a time string (HH:mm) into an ISO formatted datetime string
 * @param date - Date object representing the date portion
 * @param time - Time string in HH:mm format (e.g. "14:30")
 * @returns ISO formatted datetime string
 * @example
 * mergeDateAndTime(new Date("2024-01-01"), "14:30")
 * // Returns "2024-01-01T14:30:00.000Z"
 */
export const mergeDateAndTime = (date: Date, time: string): string | undefined => {
  if (!date || !time) return undefined
  const [hours, minutes] = time.split(':')
  const dateTime = new Date(date)
  dateTime.setHours(parseInt(hours, 10))
  dateTime.setMinutes(parseInt(minutes, 10))
  dateTime.setSeconds(0)
  dateTime.setMilliseconds(0)

  return formatISO(dateTime)
}

export const mountDateRangeValue = (from: Date | undefined, to: Date | undefined, sameMonth: boolean) => {
  if (!from || !to) return ''

  const formatMonth = 'MMM'

  const formatDay = 'dd'

  const startDate = format(from, formatDay)

  const monthStart = format(from, formatMonth, { locale: ptBR })

  const endDate = format(to, formatDay)

  const endMonth = format(to, formatMonth, { locale: ptBR })

  const fullMonth = format(to, 'MMMMMM', { locale: ptBR })

  if (sameMonth) return `${startDate} a ${endDate} ${capitalizeFirstLetter(fullMonth)}`

  return `${startDate} ${capitalizeFirstLetter(monthStart)} a ${endDate} ${capitalizeFirstLetter(endMonth)}`
}
