import WrapperWhatsapp from 'components/WrapperWhatsapp'
import ScrollToTop from 'hooks/ScrollToTop'
import CannabicastHome from 'pages/CannabicastHome'
import CannectAssistant from 'pages/CannectAssistant'
import CannectEducate from 'pages/CannectEducate'
import ClassesEComposicoes from 'pages/ClassesEComposicoes'
import Community from 'pages/Community'
import CourseLesson from 'pages/CourseLesson'
import ForgotPassword from 'pages/ForgotPassword'
import IntrodutoryCoursesHome from 'pages/IntrodutoryCoursesHome'
import Login from 'pages/Login'
import Prescribers from 'pages/Prescribers'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import QuemSomos from 'pages/QuemSomos'
import Registration from 'pages/Registration'
import ScientificBasesHome from 'pages/ScientificBasesHome'
import SelectWhitepaper from 'pages/SelectWhitepaper'
import TermsOfUse from 'pages/TermsOfUse'
import WebinarsHome from 'pages/WebinarsHome'
import WhitepapersHome from 'pages/WhitepapersHome'
import WorkshopsHome from 'pages/WorkshopsHome'
import { Switch } from 'react-router-dom'

import NotFound from 'pages/404'
import BaseRecipe from 'pages/BaseRecipe'
import CannectCare from 'pages/CannectCare'
import EcosystemCannect from 'pages/EcosystemCannect'
import NewPassword from 'pages/NewPassword'
import NotAuthorized from 'pages/NotAuthorized'
import ProductDetails from 'pages/ProductDetails'

import { BaseCientifica } from 'components/CannectCienciaComponents/BasesCientificas'
import AccountRecipes from 'pages/AccountRecipes'
import BaseRecipeHelp from 'pages/BaseRecipeHelp'
import BlankRecipeCheckout from 'pages/BlankRecipe'
import HandleRecipeUpload from 'pages/BlankRecipe/Step1'
import SelectProduct from 'pages/BlankRecipe/Step2'
import PersonalDataForm from 'pages/BlankRecipe/Step3'
import ConfirmationDocsUpload from 'pages/BlankRecipe/Step4'
import OrderConfirmation from 'pages/BlankRecipe/Step5'
import Brands from 'pages/Brands'
import BrandsDetails from 'pages/BrandsDetails'
import NewProductSelection from 'pages/CannectAssistant/components/AlreadyKnow/NewProductSelection'
import BasesCientificas from 'pages/CannectCiencia/BasesCientificas'
import Categories from 'pages/NewBlog/Categories'
import SlugPost from 'pages/NewBlog/SlugPost'
import NewCart from 'pages/NewCart'
import NewCartCod from 'pages/NewCartCOD'
import NewCartDocumentsCOD from 'pages/NewCartDocumetsCOD'
import Treatments from 'pages/Treatments'
import UserPanel from 'pages/UserPanel'
import NewCartCheckout from '../pages/NewCartCheckout'

import Cobranca from '@cannect/pages/Cobranca'
import AppointmentScheduling from 'pages/AppointmentScheduling'
import AnalyzeCannectCOD from 'pages/NewCartCOD/AnalyzeCannect/AnalyzeCannect'
import Layout from '../components/Layout'
import Products from '../pages/Products'
import Route from './Route'

import { HeaderComponent } from '@cannect/new-components/organisms'
import CalendarioOnboarding from '@cannect/pages/CalendarioOnboarding'
import { DigitalJourneyPage } from '@cannect/pages/DigitalJourney'
import AnalyzeCannect from '@cannect/pages/NewCart/AnalyzeCannect/AnalyzeCannect'
import NewCartDocuments from '@cannect/pages/NewCartDocumets'
import Nutraceuticos from '@cannect/pages/Nutraceuticos'
import NutraceuticosAnsInso from '@cannect/pages/NutraceuticosAnsInso'
import NutraceuticosInflamatorios from '@cannect/pages/NutraceuticosInflamatorios'
import ProductDetailsSupplements from '@cannect/pages/ProductDetailsSupplements'
import { WebinarSubscriptionPage } from '@cannect/pages/WebinarSubscription'
import QrCode from 'pages/CannectAssistant/components/EmitRecipe/DigitalSignatureModal/VidaasQrCode/QrCode'
import { Documents } from 'pages/Documents'
import OrderDetails from 'pages/NewCartCOD/OrderDetails'
import ContainerModalAnvisaAndFlowers from '../components/ContainerModalAnvisaAndFlowers'
import Account from '../pages/Account'
import Demand from '../pages/Demand'
import DemandDetails from '../pages/DemandDetails'
import MissingDocuments from '../pages/MissingDocuments'
import ValidateDocuments from '../pages/NewCartDocumets/ValidateDocuments'
import ProductsSupplements from '../pages/ProductsSupplements'
import ShoppingCart from '../pages/ShoppingCart'
import { usePreserveUTM } from './PreserverUTM'
import { RouteDoctor, RoutePatientOrDoctor } from './PrivateRoutes'

function Routes() {
  usePreserveUTM()
  return (
    <>
      <WrapperWhatsapp />
      <HeaderComponent />
      <ScrollToTop />
      <ContainerModalAnvisaAndFlowers />
      <Switch>
        <Layout>
          <Route path="/receitas/:token" exact component={BaseRecipe} />
          <Route path="/receitas/:token/ajuda" exact component={BaseRecipeHelp} />
          <Route path="/cadastro" component={Registration} />
          <Route path="/login" component={Login} />
          <Route path="/blog/:category" exact component={Categories} />
          <Route path="/blog/:category/:slug" component={SlugPost} />
          <Route path="/tratamentos" exact component={Treatments} />
          <Route path="/classes-e-composicoes" exact component={ClassesEComposicoes} />
          <Route path="/comu" exact component={Community} />
          <Route exact path="/termos-de-uso" component={TermsOfUse} />
          <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
          <Route exact path="/politica-de-agendamento" component={PrivacyPolicy} />
          <Route exact path="/esqueci-minha-senha" component={ForgotPassword} />
          <Route exact path="/nova-senha" component={NewPassword} />
          <Route exact path="/sobre-nos" component={QuemSomos} />
          <Route exact path="/ecossistema-cannect" component={EcosystemCannect} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/nao-autorizado" component={NotAuthorized} />
          <Route path="/whitepapers/:id" component={SelectWhitepaper} />
          <Route path="/profissionais-parceiros-cannabis-medicinal" component={Prescribers} />
          <Route path="/marcas" exact component={Brands} />
          <Route path="/marcas/:brandName" exact component={BrandsDetails} />
          <Route path="/cannect-ciencia" exact component={BasesCientificas} />
          <Route path="/bases-cientificas/:id" component={BaseCientifica} />
          <Route path="/carrinho-wa-novo" component={NewCart} />
          <Route path="/carrinho-wa" component={NewCart} />
          <Route path="/carrinho-wa2" component={NewCartCod} />
          <Route path="/validacao-documentos/:order_id?" component={Documents} />
          <Route path="/documentos-wa" component={NewCartDocuments} />
          <Route path="/documentos-wa2" component={NewCartDocumentsCOD} />
          <Route path="/finalizar-compra-wa/:cart_id" component={NewCartCheckout} />
          <RoutePatientOrDoctor path="/finalizar-compra/:cart_id" component={NewCartCheckout} />
          <RoutePatientOrDoctor path="/validate-documentos" component={ValidateDocuments} />
          <Route path="/validate-documentos-wa" component={ValidateDocuments} />
          <Route path="/onboarding-calendar" component={CalendarioOnboarding} />
          <Route path="/analise-cannect-wa" component={AnalyzeCannect} />
          <Route path="/analise-cannect-wa2" component={AnalyzeCannectCOD} />
          <Route path="/pagamento/:type/:order_id/:charge_id" component={Cobranca} />
          <Route path="/webinar-inscricao/:webinar_title" component={WebinarSubscriptionPage} />
          <RoutePatientOrDoctor path="/analise-cannect" component={AnalyzeCannectCOD} />
          <Route path="/detalhes-do-pedido/:cart_id" component={OrderDetails} />
          <Route exact path="/agendamento/:cart_id?" component={AppointmentScheduling} />
          <Route exact path="/produtos/:pagesProducts" component={Products} />
          <Route path="/produtos_suplementos/:pagesProducts" exact component={ProductsSupplements} />
          <RoutePatientOrDoctor path="/detalhes-produtos/:id" exact component={ProductDetails} />
          <Route path="/detalhes-produtos_suplementos/:id" exact component={ProductDetailsSupplements} />
          <RoutePatientOrDoctor path="/painel-do-usuario" component={UserPanel} />
          <RoutePatientOrDoctor path="/assistente" component={CannectAssistant} />
          <RoutePatientOrDoctor
            exact
            path="/suplementos/protocolo-ansiedade-e-insonia"
            component={NutraceuticosAnsInso}
          />
          <RoutePatientOrDoctor
            exact
            path="/suplementos/protocolo-dor-e-inflamacao"
            component={NutraceuticosInflamatorios}
          />
          <Route exact path="/suplementos" component={Nutraceuticos} />
          <RoutePatientOrDoctor path="/meus-favoritos" component={ShoppingCart} />
          <RoutePatientOrDoctor exact path="/minhas-receitas" component={AccountRecipes} />
          <RoutePatientOrDoctor exact path="/conta" component={Account} />
          <RoutePatientOrDoctor exact path="/pedido" component={Demand} />
          <RoutePatientOrDoctor exact path="/pedido-detalhes" component={DemandDetails} />
          <RoutePatientOrDoctor exact path="/documentos-faltantes" component={MissingDocuments} />
          <RoutePatientOrDoctor path="/cannect-cuida" component={CannectCare} />
          <RoutePatientOrDoctor exact path="/receita-branca/selecao-produtos" component={SelectProduct} />
          <RoutePatientOrDoctor exact path="/receita-branca/endereco-entrega" component={PersonalDataForm} />
          <RoutePatientOrDoctor exact path="/receita-branca/documentos-pessoais" component={ConfirmationDocsUpload} />
          <RoutePatientOrDoctor exact path="/receita-branca/aprovacao-anvisa" component={OrderConfirmation} />
          <RoutePatientOrDoctor exact path="/receita-branca/confirmacao-pedido" component={BlankRecipeCheckout} />
          <RoutePatientOrDoctor path="/carrinho" component={NewCartCod} verifyStatus />
          <RoutePatientOrDoctor path="/documentos" component={NewCartDocuments} verifyStatus />
          <RoutePatientOrDoctor
            exact
            path="/receita-branca/inserir-receita"
            verifyStatus
            component={HandleRecipeUpload}
          />
          <RouteDoctor exact path="/educa/cursos-introdutorios" component={IntrodutoryCoursesHome} />
          <RouteDoctor path="/educa/cursos-introdutorios/:id" component={CourseLesson} />
          <RouteDoctor exact path="/educa" component={CannectEducate} />
          <RouteDoctor exact path="/educa/bases-cientificas" component={ScientificBasesHome} />
          <RouteDoctor path="/educa/podcasts" component={CannabicastHome} />
          <RouteDoctor path="/whitepapers" component={WhitepapersHome} />
          <RouteDoctor path="/educa/webnars" component={WebinarsHome} />
          <RouteDoctor path="/educa/workshops" component={WorkshopsHome} />
          <RouteDoctor exact path="/produtos_assistente/:pagesProducts" component={NewProductSelection} />
          <RouteDoctor path="/sign/qrcode" component={QrCode} />
          {/* Digital Journey */}
          <Route path="/jornada-digital" component={DigitalJourneyPage} />
        </Layout>
      </Switch>
    </>
  )
}
export default Routes
