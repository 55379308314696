import InputMask from 'react-input-mask'
import { CustomerSchema, CustomerSchemaType } from '../../utils/customerSchema'
import { CustomerProps } from '../index'
import * as Style from './styles'

interface FormCustomerProps {
  isMobile: boolean
  changeCustomer: ({
    fieldToChange,
    newValue,
    error
  }: {
    fieldToChange: string
    newValue: string | null
    error: string
  }) => void
  customer: CustomerProps
  errors: Record<string, string>
}

function FormCustomer({ changeCustomer, customer, errors }: FormCustomerProps) {
  function onlyNumbers(input: string) {
    return input.replace(/[^0-9]/g, '')
  }

  const formatDateToInput = (isoDate?: string) => {
    if (!isoDate) return ''
    // Check if the date is already in DD/MM/YYYY format
    if (isoDate.includes('/')) return isoDate
    // If it's in ISO format, convert it
    const [year, month, day] = isoDate.split('-')
    return `${day}/${month}/${year}`
  }

  const formatPhoneNumber = (phone: string) => {
    const cleaned = phone.replace(/\D/g, '')
    if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)})${cleaned.slice(2, 7)}-${cleaned.slice(7)}`
    }

    return cleaned
  }

  const validateField = (fieldName: keyof CustomerSchemaType, value: string | null): string => {
    const schema = CustomerSchema.shape[fieldName]
    const result = schema.safeParse(value)
    return result.success ? '' : result.error.errors[0].message
  }

  const handleChange = (fieldToChange: keyof CustomerSchemaType, newValue: string | null) => {
    const error = validateField(fieldToChange, newValue)
    changeCustomer({ fieldToChange, newValue, error })
  }

  return (
    <Style.FormContainer>
      <div className="input-groups">
        <div>
          <span>Nome *</span>
          <input
            type="text"
            id="name"
            required
            disabled
            value={customer?.name || ''}
            onChange={(event) => handleChange('name', event.target.value)}
          />
          {errors.name && <Style.ErrorMessage>{errors.name}</Style.ErrorMessage>}
        </div>
        <div>
          <span>Email *</span>
          <input
            type="text"
            id="email"
            required
            disabled
            value={customer?.email || ''}
            onChange={(event) => handleChange('email', event.target.value)}
          />
          {errors.email && <Style.ErrorMessage>{errors.email}</Style.ErrorMessage>}
        </div>
        <div>
          <span>CPF *</span>
          <InputMask
            mask="999.999.999-99"
            type="text"
            id="cpf"
            required
            disabled
            value={customer?.cpf || ''}
            onChange={(event) => {
              const onlyNumValue = onlyNumbers(event.target.value)
              handleChange('cpf', onlyNumValue)
            }}
          />
          {errors.cpf && <Style.ErrorMessage>{errors.cpf}</Style.ErrorMessage>}
        </div>
      </div>

      <div className="input-groups">
        <div>
          <span>Data de Aniversário *</span>
          <InputMask
            mask="99/99/9999"
            type="text"
            id="birthday"
            required
            value={formatDateToInput(customer?.birthday)}
            onChange={(event) => handleChange('birthday', event.target.value)}
          />
          {errors.birthday && <Style.ErrorMessage>{errors.birthday}</Style.ErrorMessage>}
        </div>
        <div>
          <span>Celular *</span>
          <input
            type="text"
            id="phone"
            required
            value={formatPhoneNumber(customer?.phone || '')}
            onChange={(event) => {
              const onlyNumValue = onlyNumbers(event.target.value)
              handleChange('phone', onlyNumValue)
            }}
          />
          {errors.phone && <Style.ErrorMessage>{errors.phone}</Style.ErrorMessage>}
        </div>
      </div>

      <div className="input-groups">
        <div>
          <span>Telefone</span>
          <InputMask
            mask={customer?.phone_residencial?.length === 10 ? '(99)9999-9999' : ''}
            maskChar={null}
            type="text"
            id="phone_residencial"
            value={customer?.phone_residencial || ''}
            onChange={(event) => {
              const onlyNumValue = onlyNumbers(event.target.value)
              handleChange('phone_residencial', onlyNumValue)
            }}
          />
          {errors.phone_residencial && customer?.phone_residencial && (
            <Style.ErrorMessage>{errors.phone_residencial}</Style.ErrorMessage>
          )}
        </div>
        <div>
          <span>Gênero/Sexo</span>
          <select
            value={customer?.gender || ''}
            onChange={(event) => {
              const value = event.target.value || null
              handleChange('gender', value)
            }}>
            <option value="">Prefiro não responder</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
          {errors.gender && <Style.ErrorMessage>{errors.gender}</Style.ErrorMessage>}
        </div>
      </div>
    </Style.FormContainer>
  )
}

export default FormCustomer
