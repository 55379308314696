export const tailwindColors = {
  background: {
    DEFAULT: 'hsl(var(--background))',
    light: '#F3F3F3',
    dark: '#424242'
  },
  black: {
    DEFAULT: '#0a0b0a'
  },
  primary: {
    DEFAULT: 'hsl(var(--primary))',
    foreground: 'hsl(var(--primary-foreground))',
    50: '#F9F4E7',
    100: '#ECEFE5',
    200: '#D9DFCB',
    300: '#C5CEB0',
    400: '#B2BE96',
    500: '#9FAE7C',
    600: '#8C9E62',
    700: '#748351',
    750: '#6B7B3C',
    800: '#5D6941',
    900: '#383F27',
    1000: '#2A2F1D'
  },
  secondary: {
    DEFAULT: 'hsl(var(--secondary))',
    foreground: 'hsl(var(--secondary-foreground))',
    100: '#EDECE7',
    200: '#DBD8D0',
    300: '#C9C5B8',
    400: '#B7B1A1',
    500: '#A69E89',
    600: '#948B72',
    700: '#7d755f',
    800: '#585343',
    900: '#464135',
    1000: '#333027'
  },
  tertiary: {
    DEFAULT: '#BC6C25',
    100: '#FCF4EE',
    200: '#F2D4BB',
    300: '#E4AA77',
    400: '#DD9455',
    500: '#D77F33',
    600: '#BC6C25',
    700: '#93541D',
    800: '#784518',
    900: '#502E10'
  },
  neutral: {
    50: '#FAFAFA',
    100: '#e3e3e3',
    200: '#cccbcb',
    300: '#b5b3b3',
    400: '#9f9c9c',
    500: '#898483',
    600: '#726c6c',
    700: '#5a5555',
    800: '#433f3e',
    900: '#2b2928',
    950: '#151413'
  },
  gray: {
    50: '#FAFAFA',
    100: '#E5E5E5',
    200: '#D9D9D9',
    300: '#b0b0b0',
    400: '#858585',
    500: '#6d6d6d',
    600: '#646464',
    700: '#4f4f4f',
    800: '#454545',
    900: '#3d3d3d',
    950: '#262626'
  },
  muted: {
    DEFAULT: 'hsl(var(--muted))',
    foreground: 'hsl(var(--muted-foreground))'
  },
  success: {
    100: '#e1ecc9',
    200: '#c2d894',
    400: '#A4C55E',
    700: '#769536'
  },
  warning: {
    100: '#ffeec2',
    200: '#fede84',
    400: '#FECD47',
    600: '#e0a401'
  },
  information: {
    100: '#b7c5f6',
    200: '#82A0F0',
    300: '#5880EA',
    700: '#0040ce'
  },
  error: {
    100: '#f3bcc1',
    200: '#e87883',
    700: '#DC3545',
    900: '#a21c29'
  },
  critical: {
    50: '#fdf3f3',
    100: '#fce4e4',
    200: '#fbcdcd',
    300: '#f6abab',
    400: '#ef7a7a',
    500: '#e24444',
    600: '#d03232',
    700: '#af2626',
    800: '#912323',
    900: '#792323',
    950: '#410e0e'
  },
  link: {
    default: '#4285f4'
  },
  border: 'hsl(var(--border))',
  input: 'hsl(var(--input))',
  ring: 'hsl(var(--ring))',

  foreground: 'hsl(var(--foreground))',
  destructive: {
    DEFAULT: 'hsl(var(--destructive))',
    foreground: 'hsl(var(--destructive-foreground))'
  },

  accent: {
    DEFAULT: 'hsl(var(--accent))',
    foreground: 'hsl(var(--accent-foreground))'
  },
  popover: {
    DEFAULT: 'hsl(var(--popover))',
    foreground: 'hsl(var(--popover-foreground))'
  },
  card: {
    DEFAULT: 'hsl(var(--card))',
    foreground: 'hsl(var(--card-foreground))'
  },
  flavors: {
    default: {
      100: '#C5CEB0',
      900: '#5D6941'
    },
    acid: {
      100: '#FFECAD',
      900: '#A57E00'
    },
    sweet: {
      100: '#FFD0E4',
      900: '#AD3D6C'
    },
    monophagic: {
      100: '#E0C879',
      900: '#BC5B00'
    },
    oilseeds: {
      100: '#C5C392',
      900: '#585343'
    }
  }
}
