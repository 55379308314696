import styled from 'styled-components'

import { theme } from 'design-cannect'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  .page {
    margin: 24px 0;
  }

  .modal-cannect-ciencia {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`

export const PromotionalImage = styled.img`
  width: 100%;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  @media ${MOBILE_WIDTH} {
    margin-bottom: 30px;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  svg {
    width: 30px;
    height: 30px;
  }
`

export const HeaderTitle = styled.h1`
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #3d3d3d;
  span {
    color: #21976e;
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
    font-size: 30px;
  }
`

export const HeaderSubtitle = styled.h2`
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${theme.colors.darkGrey};
  margin-top: 48px;
  @media ${MOBILE_WIDTH} {
    font-size: 16px;
    margin-top: 24px;
  }
`

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`

export const BackButton = styled.button`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  width: 89px;
  height: 36px;
  background-color: transparent;
  border: 2px solid #748351;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #748351;
  margin: 24px 0;
`
