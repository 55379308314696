import * as React from 'react'

import { cn } from '@cannect/lib/utils'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { Typography } from '../Typography'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md bg-primary-1000 px-3 py-1.5 text-xs text-neutral-50 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TTooltip = {
  content: string | React.ReactNode
  children: React.ReactNode
  delayDuration?: number
  asChild?: boolean
  hideArrow?: boolean
  disableHoverableContent?: boolean
}

const TooltipText = ({
  content,
  children,
  asChild = true,
  delayDuration = 100,
  disableHoverableContent = false
}: TTooltip) => {
  return (
    <TooltipProvider delayDuration={delayDuration} disableHoverableContent={disableHoverableContent}>
      <Tooltip>
        <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
        <TooltipContent className="max-w-screen-md">
          {typeof content === 'string' ? (
            <Typography.Text type="captionOne" className="!text-neutral-50">
              {content}
            </Typography.Text>
          ) : (
            content
          )}
          <TooltipPrimitive.TooltipArrow />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export { Tooltip, TooltipContent, TooltipProvider, TooltipText, TooltipTrigger }
