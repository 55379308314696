import React, { PropsWithChildren } from 'react'

import { Calendar, CalendarProps } from '../Calendar'
import { Popover } from '../Popover'
import { cn } from '@cannect/lib/utils'

export const DatePicker = ({
  children,
  className,
  closeRef,
  isDisabled = false,
  ...props
}: PropsWithChildren<
  CalendarProps & {
    closeRef: React.RefObject<HTMLButtonElement>
    isDisabled?: boolean
  }
>) => {
  return (
    <Popover.Root>
      <Popover.Trigger disabled={isDisabled} asChild>
        {children}
      </Popover.Trigger>
      <Popover.Content
        align="start"
        className={cn(className, 'flex w-auto flex-col items-center justify-center bg-white p-4')}>
        <Popover.Close ref={closeRef} />
        <Calendar {...props} initialFocus />
      </Popover.Content>
    </Popover.Root>
  )
}
