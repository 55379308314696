import { keepPreviousData, useQuery } from '@tanstack/react-query'
import * as requests from './requests'
import { serializeFiltersOptions } from './serializers'
import { TSchedulingDatesDefaultParams } from './types'

export const KEY_GET_FILTERS_EXPERTISES = 'KEY_GET_FILTERS_EXPERTISES'
export const KEY_GET_FILTERS_PRESCRIBERS = 'KEY_GET_FILTERS_PRESCRIBERS'
export const KEY_GET_FILTERS_PATHOLOGIES = 'KEY_GET_FILTERS_PATHOLOGIES'
export const KEY_GET_AVAILABLE_PRESCRIBERS_LIST = 'KEY_GET_AVAILABLE_PRESCRIBERS_LIST'
export const KEY_GET_AVAILABLE_DATES = 'KEY_GET_AVAILABLE_DATES'
export const KEY_GET_AVAILABLE_PRESCRIBERS = 'KEY_GET_AVAILABLE_PRESCRIBERS'

export const useGetExpertisesFiltersOptions = () => {
  return useQuery({
    queryKey: [KEY_GET_FILTERS_EXPERTISES],
    queryFn: () => requests.getExpertisesFiltersOptions(),
    select: (data) => serializeFiltersOptions(data.expertises)
  })
}

export const useGetPathologiesFiltersOptions = () => {
  return useQuery({
    queryKey: [KEY_GET_FILTERS_PATHOLOGIES],
    queryFn: () => requests.getPathologiesFiltersOptions(),
    select: (data) => serializeFiltersOptions(data.pathologies)
  })
}

export const useGetPrescribersFiltersOptions = () => {
  return useQuery({
    queryKey: [KEY_GET_FILTERS_PRESCRIBERS],
    queryFn: () => requests.getPrescribersFiltersOptions(),
    select: (data) => serializeFiltersOptions(data.prescribers)
  })
}

export const useGetAvailableDates = ({ filterBy }: TSchedulingDatesDefaultParams) => {
  return useQuery({
    queryKey: [KEY_GET_AVAILABLE_DATES, { ...filterBy }],
    queryFn: () => requests.getAvailableDates({ filterBy }),
    meta: {
      errorMessage: 'Houve um erro ao recuperar as datas disponíveis.'
    },
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled: !!filterBy?.value && !!filterBy?.type
  })
}

export const useGetAvailablePrescribers = ({ filterBy, dateTime, orderBy, sortBy }: TSchedulingDatesDefaultParams) => {
  return useQuery({
    queryKey: [KEY_GET_AVAILABLE_PRESCRIBERS, { ...filterBy, dateTime, orderBy, sortBy }],
    queryFn: () => requests.getAvailablePrescribers({ filterBy, dateTime, orderBy, sortBy }),
    meta: {
      errorMessage: 'Houve um erro ao recuperar os profissionais disponíveis.'
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled: !!filterBy?.value && !!filterBy?.type
  })
}
