import { useRef } from 'react'

import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { CalendarIcon } from 'lucide-react'
import { Control, FieldValues, Path } from 'react-hook-form'
import { VariantProps } from 'tailwind-variants'
import { CalendarProps } from '@cannect/new-components/atoms/Calendar'
import { InputProps } from '@cannect/new-components/atoms/Form/Input'
import { formItemVariants } from '@cannect/new-components/atoms/FormController'
import { Form, FormController } from '@cannect/new-components/atoms'
import { cn } from '@cannect/lib/utils'

type DatePickerFieldProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label?: string
  description?: string
  dateFormat?: string
  calendarProps?: Omit<CalendarProps, 'selected' | 'onSelect'>
  inputProps?: Omit<InputProps, 'form'>
  formItemClassName?: string
  onDaySelect?: (date: Date) => void
} & VariantProps<typeof formItemVariants>

export const DatePickerField = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  description,
  dateFormat = 'dd/MM/yyyy',
  calendarProps,
  alignment: formItemAlignment,
  inputProps = {},
  formItemClassName,
  onDaySelect
}: DatePickerFieldProps<TFieldValues>) => {
  const { placeholder = 'Selecione uma data', leftElement = <CalendarIcon />, ...restInputProps } = inputProps

  const popOverCloseRef = useRef<HTMLButtonElement | null>(null)
  return (
    <FormController.FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormController.FormItem
          alignment={formItemAlignment}
          className={cn(formItemClassName, restInputProps?.readOnly && 'pointer-events-none')}>
          {label && <FormController.FormLabel>{label}</FormController.FormLabel>}
          <FormController.FormControl>
            <Form.DatePicker
              isDisabled={restInputProps?.isDisabled}
              mode="single"
              selected={field.value}
              closeRef={popOverCloseRef}
              onSelect={(date: any) => {
                if (!date) return

                field.onChange(date)
                onDaySelect?.(date)

                popOverCloseRef.current?.click()
              }}
              {...calendarProps}>
              <div>
                <Form.Input
                  {...restInputProps}
                  placeholder={placeholder}
                  leftElement={leftElement}
                  isInvalid={!!fieldState.error}
                  onBlur={field.onBlur}
                  value={field.value ? format(field.value, dateFormat, { locale: ptBR }) : ''}
                  readOnly
                />
              </div>
            </Form.DatePicker>
          </FormController.FormControl>
          {description && <FormController.FormDescription>{description}</FormController.FormDescription>}
          <FormController.FormMessage />
        </FormController.FormItem>
      )}
    />
  )
}

DatePickerField.displayName = 'DatePickerField'
