import { cn } from '@cannect/lib/utils'
import { Typography } from '@cannect/new-components/atoms'
import { ElementType, ReactNode } from 'react'

type THomeFeatureCard = {
  icon: ElementType
  title: string | ReactNode
  subtitle: string | ReactNode
  classNames?: {
    icon?: string
    title?: string
    subtitle?: string
  }
}

export const HomeFeatureCard = ({ icon: IconElement, title, subtitle, classNames }: THomeFeatureCard) => {
  return (
    <div className="flex h-[340px] min-w-[273px] flex-col items-start gap-[16px] rounded bg-gray-50 py-6 pl-6 pr-5 text-left text-neutral-800">
      {IconElement && <IconElement className={cn('h-12 w-12 text-primary-700', classNames?.icon)} />}
      {typeof title === 'string' ? (
        <Typography.Text
          weight="semibold"
          type="paragraphOne"
          className={cn('text-lg leading-[120%] text-secondary-1000', classNames?.title)}>
          {title}
        </Typography.Text>
      ) : (
        title
      )}

      {typeof subtitle === 'string' ? (
        <Typography.Text type="paragraphTwo" className={cn('leading-[150%] md:text-lg', classNames?.subtitle)}>
          {subtitle}
        </Typography.Text>
      ) : (
        subtitle
      )}
    </div>
  )
}
