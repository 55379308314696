/* eslint-disable no-restricted-syntax */
import api from '@cannect/services/api'
import { TDefaultOptionResponse } from '@cannect/services/common'
import { TResponseStandard } from '@cannect/services/types'
import { TSchedulingDatesDefaultParams, TSchedulingPrescriberListItem } from './types'

export const getExpertisesFiltersOptions = async () => {
  return api
    .get<TResponseStandard<TDefaultOptionResponse[], 'expertises'>>('/scheduling/filters/expertises')
    .then((res) => res.data)
}

export const getPathologiesFiltersOptions = async () => {
  return api
    .get<TResponseStandard<TDefaultOptionResponse[], 'pathologies'>>('/scheduling/filters/pathologies')
    .then((res) => res.data)
}

export const getPrescribersFiltersOptions = async () => {
  return api
    .get<TResponseStandard<TDefaultOptionResponse[], 'prescribers'>>('/scheduling/filters/prescribers')
    .then((res) => res.data)
}

export const getAvailableDates = async ({ filterBy }: TSchedulingDatesDefaultParams) => {
  return api
    .get<
      TResponseStandard<number[], 'available_date_times'>
    >(`/scheduling/availability/dates?${filterBy?.type}=${filterBy?.value}`)
    .then((res) => res.data)
}

export const getAvailablePrescribers = async ({
  filterBy,
  dateTime,
  sortBy,
  orderBy
}: TSchedulingDatesDefaultParams) => {
  return api
    .get<
      TResponseStandard<TSchedulingPrescriberListItem[], 'prescribers'>
    >(`/scheduling/availability/prescribers?${filterBy?.type}=${filterBy?.value}`, { params: { dateTime, sortBy, orderBy } })
    .then((res) => res.data)
}
