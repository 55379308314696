import { useAvailableTimestamps } from '@cannect/hooks/useAvailableTimestamps'
import { Button } from '@cannect/new-components/atoms'
import { DatePickerField, SelectField } from '@cannect/new-components/molecules'
import {
  useGetAvailableDates,
  useGetExpertisesFiltersOptions,
  useGetPathologiesFiltersOptions,
  useGetPrescribersFiltersOptions
} from '@cannect/services/resources/scheduling'
import { formatDate } from '@cannect/utils/date'
import { useScheduling } from 'hooks/useScheduling'
import { SearchIcon, XCircle } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'

export type FilterBy = 'expertise' | 'pathology' | 'prescriber'

type FilterFormProps = {
  isLoading: boolean
  showFilterByDate?: boolean
}

export default function FilterForm({ isLoading, showFilterByDate = false }: FilterFormProps) {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { setValue, watch, control } = useFormContext()
  const [filterBy, setFilterBy] = useState<FilterBy>('expertise')
  const [selectedFilter, setSelectedFilter] = useState<{ id: string } | null>(null)

  const { resetContextValues, setSelectedPrescriber } = useScheduling()

  const currentFilter = watch('filter')
  const searchDate = watch('search_date')

  const currentFilterBy = {
    type: filterBy,
    value: selectedFilter?.id
  }

  const { data: expertisesList, isLoading: isLoadingExpertises } = useGetExpertisesFiltersOptions()
  const { data: pathologiesList, isLoading: isLoadingPathologies } = useGetPathologiesFiltersOptions()
  const { data: prescribersList, isLoading: isLoadingPrescribers } = useGetPrescribersFiltersOptions()
  const { data: availableDatesState, isLoading: isLoadingAvailableDates } = useGetAvailableDates({
    filterBy: currentFilterBy
  })

  const { isDateDisabled } = useAvailableTimestamps(availableDatesState?.available_date_times)

  const isLoadingLists = useMemo(
    () => isLoadingExpertises || isLoadingPathologies || isLoadingPrescribers,
    [isLoadingExpertises, isLoadingPathologies, isLoadingPrescribers]
  )

  const resetValues = () => {
    setValue('scheduling_date', null)
    setValue('scheduling_time', '')
    setValue('prescriber', null)
    setSelectedPrescriber(null)
    resetContextValues()
  }

  const handleChangeFilterType = (filter: FilterBy) => {
    setFilterBy(filter)
    setSelectedFilter(null)
    setValue('search_date', null)
    resetValues()

    history.push({
      pathname: location.pathname,
      search: ''
    })
  }

  const handleSelectFilter = (id: string) => {
    setSelectedFilter({ id })
  }

  const handleDateChange = (date: Date | string | null) => {
    setValue('search_date', date)
  }

  const handleSearch = () => {
    if (!selectedFilter) return

    params.set('filter_by', filterBy)
    params.set('filter_by_id', selectedFilter.id)

    if (searchDate) {
      params.set('searchDate', formatDate(searchDate, 'yyyy-MM-dd'))
    }

    history.push({ search: params.toString() })
  }

  const handleClearDate = () => {
    setValue('search_date', null)
    params.delete('searchDate')
    history.push({ search: params.toString() })
  }

  const selectFilters = {
    expertise: {
      id: 'expertise-filter',
      placeholder: 'Selecione a especialidade',
      options: expertisesList || []
    },
    pathology: {
      id: 'pathology-filter',
      placeholder: 'Selecione o sintoma',
      options: pathologiesList || []
    },
    prescriber: {
      id: 'prescriber-filter',
      placeholder: 'Selecione o profissional',
      options: prescribersList || []
    }
  }

  useEffect(() => {
    const filterByParam = params.get('filter_by') as FilterBy
    const filterById = params.get('filter_by_id')
    const searchDateParam = params.get('searchDate')

    if (filterByParam && filterById) {
      setFilterBy(filterByParam)
      setSelectedFilter({ id: filterById })
      setValue('filter', filterById)

      if (searchDateParam) {
        setValue('search_date', new Date(`${searchDateParam}T00:00:00-03:00`))
      }
    } else {
      setSelectedFilter(null)
      setValue('filter', null)
      setValue('search_date', null)
    }
  }, [location.search])

  useEffect(() => {
    const searchDateParam = params.get('searchDate')
    const date = searchDateParam ? new Date(`${searchDateParam}T00:00:00-03:00`) : null

    if (date && availableDatesState && isDateDisabled(date)) {
      setValue('search_date', null)
      params.delete('searchDate')
      history.push({ search: params.toString() })
    }
  }, [availableDatesState, location.search])

  const currentSelectedFilter = selectFilters[filterBy]

  return (
    <div className="mt-8 w-full rounded bg-neutral-50 bg-opacity-50 p-1 backdrop-blur-[64px]">
      <div className="grid gap-2 rounded bg-white p-1">
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2 grid grid-cols-2 items-center gap-2 lg:col-span-1 lg:grid-cols-3">
            <Button
              disabled={isLoading}
              variant={filterBy === 'expertise' ? 'primary_light' : 'outline_light'}
              onClick={() => handleChangeFilterType('expertise')}
              className="rounded-full border px-8 font-normal transition-all">
              Especialidade
            </Button>
            <Button
              disabled={isLoading}
              variant={filterBy === 'pathology' ? 'primary_light' : 'outline_light'}
              onClick={() => handleChangeFilterType('pathology')}
              className="rounded-full border px-8 font-normal transition-all">
              Sintoma
            </Button>
            <Button
              disabled={isLoading}
              variant={filterBy === 'prescriber' ? 'primary_light' : 'outline_light'}
              onClick={() => handleChangeFilterType('prescriber')}
              className="col-span-2 rounded-full border px-8 font-normal transition-all lg:col-span-1">
              Profissional da saúde
            </Button>
          </div>

          <div className="col-span-2 grid gap-2 lg:col-span-1 lg:grid-cols-[1fr_auto_auto]">
            <SelectField
              menuPortalTarget={document.body}
              isDisabled={isLoading}
              isLoading={isLoadingLists}
              variant="outlined"
              control={control}
              name="filter"
              id={currentSelectedFilter.id}
              onSelectOption={(option: any) => {
                if (option) {
                  handleSelectFilter(option)
                }
              }}
              placeholder={currentSelectedFilter.placeholder}
              options={currentSelectedFilter.options}
            />

            {currentFilter && (
              <div className="grid grid-cols-[1fr_auto_auto] gap-2 lg:col-span-2">
                {showFilterByDate && (
                  <div className="relative">
                    <DatePickerField
                      calendarProps={{
                        captionLayout: 'buttons',
                        modifiers: {
                          disabled: isDateDisabled
                        },
                        disabled: !currentFilter ||
                          isLoadingAvailableDates || {
                            before: new Date()
                          }
                      }}
                      control={control}
                      name="search_date"
                      onDaySelect={(date) => handleDateChange(date)}
                      inputProps={{
                        placeholder: 'Data (opcional)',
                        variant: 'filledLight',
                        isDisabled: isLoading || !currentFilter,
                        isLoading: isLoadingAvailableDates
                      }}
                    />
                    {searchDate && (
                      <Button
                        size="icon"
                        unstyled
                        onClick={handleClearDate}
                        className="absolute right-2 top-1/2 -translate-y-1/2"
                        icon={<XCircle size={16} />}
                      />
                    )}
                  </div>
                )}
                <Button
                  size="icon"
                  onClick={() => handleSearch()}
                  icon={<SearchIcon size={24} />}
                  isLoading={isLoading}
                  disabled={isLoadingLists}
                />
              </div>
            )}

            {!currentFilter && (
              <Button
                size="icon"
                onClick={() => handleSearch()}
                icon={<SearchIcon size={24} />}
                isLoading={isLoading}
                disabled={isLoadingLists}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
