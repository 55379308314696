import { format, setHours, setMinutes } from 'date-fns'

type TimeOption = {
  label: string
  value: string
}

/**
 * Converts Unix timestamps into formatted time options grouped by date
 * @param timestamps - Array of Unix timestamps (e.g., [1633027200000, 1633030800000])
 * @returns Grouped time options by date (e.g., { "2023-01-01": [{ label: "10:00", value: "10:00" }] })
 */
const groupTimestampsByDate = (timestamps: number[]) => {
  return timestamps.reduce(
    (grouped, timestamp) => {
      const date = format(new Date(timestamp), 'yyyy-MM-dd')
      const time = format(new Date(timestamp), 'HH:mm')
      const timeOption = { label: time, value: time }

      if (!grouped[date]) {
        // eslint-disable-next-line no-param-reassign
        grouped[date] = []
      }

      grouped[date].push(timeOption)

      // Sort chronologically
      grouped[date].sort((a, b) => {
        const [aH, aM] = a.value.split(':').map(Number)
        const [bH, bM] = b.value.split(':').map(Number)
        return setMinutes(setHours(new Date(), aH), aM).getTime() - setMinutes(setHours(new Date(), bH), bM).getTime()
      })

      return grouped
    },
    {} as Record<string, TimeOption[]>
  )
}

/**
 * Hook to manage availability from Unix timestamps
 * @param timestamps - Array of Unix timestamps representing available dates and times
 * @returns Functions to check date availability and get available times
 *
 * @example
 * const { isDateDisabled, getAvailableTimesForDate } = useAvailableTimestamps([1633027200000]);
 *
 * // Check if date should be disabled in calendar
 * isDateDisabled(new Date());
 *
 * // Get available times for selected date
 * getAvailableTimesForDate(selectedDate);
 */

export const useAvailableTimestamps = (timestamps: number[] = []) => {
  if (!timestamps?.length) {
    return {
      isDateDisabled: () => true,
      getAvailableTimesForDate: () => []
    }
  }

  const availableTimesByDate = groupTimestampsByDate(timestamps)
  const availableDates = Object.keys(availableTimesByDate)

  return {
    /**
     * Checks if the given date should be disabled (has no available times)
     */
    isDateDisabled: (date: Date) => !availableDates.includes(format(date, 'yyyy-MM-dd')),

    /**
     * Gets available time options for the given date
     */
    getAvailableTimesForDate: (date: Date | undefined): TimeOption[] => {
      if (!date) return []
      return availableTimesByDate[format(date, 'yyyy-MM-dd')] || []
    }
  }
}
