import { getEvents } from '@cannect/services/events'
import { OrderTypes } from '@cannect/types/OrderTypes'
import LoadingContainer from 'components/LoadingContainer'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useQueryString from 'hooks/useQueryString'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'
import useMediaQuery from '../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../utils/constants'
import Step from '../NewCart/Steps'
import PaymentMethods from './PaymentMethod'
import * as S from './styles'

function NewCartCheckout() {
  const [error, setError] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [address, setAddress] = useState({ shippingAddress: {}, billingAddress: {} })
  const { lastOrderCreated, loading: loadingOrders, getLastOrder } = usePrepareCart()
  const [documentList, setDocumentList] = useState({})
  const originCheck = ['acolhimento-cannect', 'B2C', 'COD B', 'Recipe', 'COD C']
  const [loadingContainer, setLoading] = useState(false)
  const history = useHistory()
  const { filters } = useQueryString()
  const cart_id = filters?.cart_id
  const validUrl = cart_id ? lastOrderCreated?.uuid : lastOrderCreated?.id

  useEffect(() => {
    getLastOrder()
  }, [])

  const onFinishPayment = (orderInfos: OrderTypes, paymentMethod: string, paymentStatus?: string) => {
    const itemsIds = orderInfos?.items?.map((item: any) => item.id)
    const eventData = {
      eventName: 'purchase',
      orderId: orderInfos?.id,
      value: orderInfos?.amount,
      items_ids: itemsIds,
      patient_id: orderInfos?.patient_id,
      payment_method: paymentMethod || '',
      coupon_id: orderInfos?.coupon_id,
      payment_status: paymentStatus || ''
    }
    getEvents.eventSendDataLayer(eventData)
  }

  const finishOrderByPaymentMethod = async () => {
    const BASE_PAYMENT_URL = cart_id ? 'order_cart_public' : 'order_cart'
    const { documents, recipes } = lastOrderCreated
    const { shippingAddress, billingAddress } = address

    try {
      setLoading(true)
      const payload = {
        documents: (documents?.flat()?.length > 0 || documents.length > 0) && (documents?.documents_order || documents),
        recipes: [recipes[recipes.length - 1]],
        shippingAddress,
        billingAddress,
        person_id: cart_id && lastOrderCreated?.person?.id
      }

      const { data } = await api.put(`/${BASE_PAYMENT_URL}/${lastOrderCreated.id}`, payload)

      const validateAnvisa = documentList.ANVISA?.length > 0 || documentList.PROCURACAO?.length > 0

      if (originCheck.some((origin) => origin === data?.order?.originCheckout) && !validateAnvisa) {
        const validator = await api.get(`anvisaAuth/order/${data?.order?.id}`)

        if (validator?.data?.success && validator?.data?.message === 'waitingDocs') {
          await api.post('anvisaAuth/launchAnvisa', { order_id: data?.order?.id })
        } else if (
          validator?.data?.success &&
          validator?.data?.message === 'inexist' &&
          documentList.IDENTIDADE?.length > 0
        ) {
          await api.post('anvisaAuth/clickAutomation', { order_id: data?.order?.id })
        }
      }

      if (data?.success === 'true') {
        history.push(`/finalizar-compra-wa/${data?.order?.id}?cart_id=${cart_id}`)
      }
    } catch (error) {
      setError(true)
      toast.error('Ocorreu um erro ao finalizar o pedido. Por favor, tente novamente.')
      return { error: true }
    } finally {
      setLoading(false)
    }
  }

  let interval: NodeJS.Timeout

  const finishOrderByPaymentMethodInterval = () => {
    interval = setInterval(async () => {
      getLastOrder()
    }, 7000)
  }

  const clearIntervalOutside = () => {
    clearInterval(interval)
  }

  const backToCart = () => {
    const url = cart_id ? `/carrinho-wa?cart_id=${cart_id}` : `/carrinho`
    history.push(url)
  }

  if (loadingOrders) return <LoadingContainer loading />

  return (
    <S.Container>
      <Step step={3} />

      <S.ContainerResumePay isMobile={isMobile}>
        <PaymentMethods
          loadingContainer={loadingContainer}
          finishOrderByPaymentMethodInterval={finishOrderByPaymentMethodInterval}
          clearIntervalOutside={clearIntervalOutside}
          finishOrderByPaymentMethod={finishOrderByPaymentMethod}
          error={error}
          hasMultipleMethods
        />
      </S.ContainerResumePay>

      <S.ButtonContainer>
        <S.BackCart onClick={backToCart}>Voltar para o inicio</S.BackCart>
      </S.ButtonContainer>
    </S.Container>
  )
}

export default NewCartCheckout
