import { Typography } from '@cannect/new-components/atoms'
import { Star } from 'lucide-react'

export type SchedulingOpinionsCardProps = {
  personDetail: {
    photo: string
    name: string
    role: string
    description: string
  }
}

export const SchedulingOpinionsCard = ({ personDetail }: SchedulingOpinionsCardProps) => {
  return (
    <div className="flex h-[354px] w-[273px] min-w-[273px] flex-col items-start justify-between space-y-4 rounded-2xl bg-gray-50 p-6 text-left">
      <div className="flex items-center gap-2">
        <img className="size-14 rounded-full object-cover" alt="person-photo" src={personDetail?.photo} />
        <div className="flex flex-col items-start">
          <Typography.Text className="font-semibold">{personDetail?.name}</Typography.Text>
          <Typography.Text type="paragraphTwo">{personDetail?.role}</Typography.Text>
        </div>
      </div>
      <Typography.Text>{personDetail?.description}</Typography.Text>
      <div className="flex items-center gap-1">
        {Array.from(Array(5)).map((_, index) => (
          <Star key={index} className="text-warning-200" fill="currentColor" />
        ))}
      </div>
    </div>
  )
}
