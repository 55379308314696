/** @type {import('tailwindcss').Config} */
import { withTV } from 'tailwind-variants/transformer'
import tailwindAnimatedPlugin from 'tailwindcss-animate'
import { tailwindColors } from './src/lib/tailwind-colors'

export default withTV({
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}', './stories/**/*.{js,jsx,ts,tsx,mdx}'],
  preflight: false,
  corePlugins: {
    preflight: false
  },
  theme: {
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px'
      }
    },
    extend: {
      screens: {
        xs: '420px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1400px'
      },
      spacing: {
        section: '64px',
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        9: '36px',
        10: '40px',
        11: '44px',
        12: '48px',
        13: '52px',
        14: '56px',
        15: '60px',
        16: '64px',
        17: '68px',
        18: '72px',
        19: '76px',
        20: '80px',
        21: '84px',
        22: '88px',
        23: '92px',
        24: '96px'
      },
      colors: {
        ...tailwindColors
        // Dont add colors here, add them to the tailwindColors object so storybook can track them
      },
      fontFamily: {
        sans: ['Poppins', 'sans-serif'],
        serif: ['Spartan', 'sans-serif'],
        openSans: ['Open Sans', 'sans-serif'],
        ebGaramond: ['EB Garamond', 'serif'],
        nunito: ['Nunito', 'sans-serif']
      },
      borderRadius: {
        DEFAULT: '8px',
        md: '4px'
      },
      fontSize: {
        xxs: '10px',
        xs: '12px',
        sm: '14px',
        base: '16px',
        lg: '18px',
        xl: '22px',
        '2xl': '24px',
        '3xl': '30px',
        '4xl': '36px',
        '5xl': '40px'
      },
      backgroundImage: {
        'hero-desktop':
          "linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), url('/src/assets/img/home/hero_bg.webp')",
        'home-faq': "url('/src/assets/img/home/home-faq-bg.png')",
        'scheduling-hero-desktop': " url('/src/assets/img/scheduling/scheduling-hero-bg-desktop.webp')"
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' }
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' }
        },
        'auto-carousel': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(calc(-100%))' }
        }
      },
      height: {
        'header-desk': '106px',
        'header-mobile': '74px'
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'auto-carousel': 'auto-carousel 200s linear infinite'
      }
    }
  },
  plugins: [tailwindAnimatedPlugin]
})
