import { SchedulingFeaturesSections } from '@cannect/new-components/organisms'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import CalendarOnboardingContainer from './CalendarOnboardingContainer'

const FormWrapper: React.FC = () => {
  const methods = useForm({
    defaultValues: {
      filter: '',
      scheduling_date: null
    }
  })

  return (
    <FormProvider {...methods}>
      <CalendarOnboardingContainer />
      <SchedulingFeaturesSections />
    </FormProvider>
  )
}

export default FormWrapper
