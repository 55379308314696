import { useTokenApi } from 'hooks/useTokenApi'
import CheckoutStep from 'pages/AppointmentScheduling/FormSteps/CheckoutStep'
import FilterByStep from 'pages/AppointmentScheduling/FormSteps/FilterByStep'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import {
  CHECKOUT_FORM_STATE,
  Coupon,
  GetSchedulingResponse,
  IAppointment,
  IAppointmentFromB2C,
  IPrescriberInfo,
  ISchedulingContext,
  IStep
} from './types'

const SchedulingContext = createContext<ISchedulingContext>({} as ISchedulingContext)

export function SchedulingProvider({ children }: { children: ReactNode }) {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState(0)
  const [appointmentFromB2C, setAppointmentFromB2C] = useState<IAppointmentFromB2C | null>(null)
  const [portalAppointment, setPortalAppointment] = useState<IAppointment | null>(null)
  const [paidOrCanceledScheduling, setPaidOrCanceledScheduling] = useState<IAppointmentFromB2C | null>(null)
  const [schedulingCartLink, setSchedulingCartLink] = useState<string>('')
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'credit_card' | 'pix'>('credit_card')
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedPrescriber, setSelectedPrescriber] = useState<IPrescriberInfo | null>(null)

  const { tokenApi } = useTokenApi()
  const [checkoutActiveStep, setCheckoutActiveStep] = useState(tokenApi ? 2 : 1)
  const [checkoutFormState, setCheckoutFormState] = useState<CHECKOUT_FORM_STATE>('simplified')
  const [validCoupon, setValidCoupon] = useState<Coupon | null>(null)
  const [shouldUpdateQrCode, setShouldUpdateQrCode] = useState<boolean>(false)

  const { pathname } = useLocation()
  const [isSchedulingFlow, setIsOnSchedulingFlow] = useState(false)

  const steps = [<FilterByStep key="filterByStep" />, <CheckoutStep key="checkoutStep" />]

  const checkoutSteps: IStep[] = [
    { index: 1, title: 'Identificação' },
    { index: 2, title: 'Revisão' },
    { index: 3, title: 'Pagamento' },
    { index: 4, title: 'Conclusão' }
  ]

  function next() {
    if (steps) {
      setCurrentStep(currentStep + 1)
    }
  }
  function back() {
    if (currentStep === 0) return history.push('/')

    return setCurrentStep(currentStep - 1)
  }

  function goTo(index: number) {
    setCurrentStep(index)
  }

  const getScheduling = async (id: string) => {
    setLoading(true)
    try {
      const response = await api.get<GetSchedulingResponse>(`/scheduling/appointment/${id}`)

      const { appointment } = response.data
      const isWaitingPaymentAppointment = ['payment_refused', 'waiting_payment']
      const statusName = appointment?.current_status?.status_description?.name

      if (isWaitingPaymentAppointment.includes(statusName)) {
        setAppointmentFromB2C(appointment)
      } else {
        setPaidOrCanceledScheduling(appointment)
      }
      goTo(1)
      setCheckoutActiveStep(3)
    } catch (e) {
      console.error(e)
      toast.error('Houve um erro ao recuperar os dados do agendamento. Tente novamente.')
      history.push('/agendamento')
    } finally {
      setLoading(false)
    }
  }

  function resetContextValues() {
    setCurrentStep(0)
    setCheckoutActiveStep(tokenApi ? 2 : 1)
    setCheckoutFormState('simplified')
    setAppointmentFromB2C(null)
    setPaidOrCanceledScheduling(null)
    setSchedulingCartLink('')
    setLoading(false)

    setSelectedPaymentMethod('credit_card')
    setValidCoupon(null)
  }

  useEffect(() => {
    if (pathname.includes('agendamento')) {
      setIsOnSchedulingFlow(true)
    } else {
      setIsOnSchedulingFlow(false)
      resetContextValues()
    }
  }, [pathname, tokenApi])

  const onRegisterSuccess = () => {
    nextCheckoutStep()
  }

  const onForgotPassword = () => {
    setCheckoutFormState('forgotPassword')
  }

  const nextCheckoutStep = () => {
    if (checkoutActiveStep < checkoutSteps.length) {
      setCheckoutActiveStep((prevState) => prevState + 1)
    }
  }

  const previousCheckoutStep = () => {
    if (checkoutActiveStep > 1) {
      if (tokenApi && checkoutActiveStep === 3) {
        console.log("You cannot go back to the 'Identificação' step when logged in.")
      } else {
        setCheckoutActiveStep(checkoutActiveStep - 1)
      }
    }
  }

  const contextValue = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      step: steps && steps[currentStep],
      steps,
      stepName: (steps && (steps[currentStep]?.key as string)) || undefined,
      isFirstStep: currentStep === 0,
      isLastStep: (steps && currentStep === steps.length - 1) || false,
      next,
      back,
      portalAppointment,
      setPortalAppointment,
      appointmentFromB2C,
      setAppointmentFromB2C,
      paidOrCanceledScheduling,
      setPaidOrCanceledScheduling,
      schedulingCartLink,
      setSchedulingCartLink,
      loading,
      setLoading,
      getScheduling,
      resetContextValues,
      isSchedulingFlow,
      onRegisterSuccess,
      onForgotPassword,
      checkoutActiveStep,
      checkoutSteps,
      nextCheckoutStep,
      previousCheckoutStep,
      checkoutFormState,
      setCheckoutFormState,
      validCoupon,
      setValidCoupon,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      shouldUpdateQrCode,
      setShouldUpdateQrCode,
      selectedPrescriber,
      setSelectedPrescriber
    }),
    [
      currentStep,
      steps,
      appointmentFromB2C,
      portalAppointment,
      schedulingCartLink,
      loading,

      isSchedulingFlow,
      checkoutActiveStep,
      checkoutFormState,
      checkoutSteps,
      validCoupon,
      selectedPaymentMethod,
      paidOrCanceledScheduling,
      shouldUpdateQrCode
    ]
  )

  return <SchedulingContext.Provider value={contextValue}>{children}</SchedulingContext.Provider>
}

export function useScheduling(): ISchedulingContext {
  const context = useContext(SchedulingContext)

  if (!context) {
    throw new Error('useScheduling must be used within a SchedulingProvider')
  }

  return context
}
