import { cn } from '@cannect/lib/utils'
import { FormController } from '@cannect/new-components/atoms'
import { CustomSelect, CustomSelectProps, TOption } from '@cannect/new-components/atoms/Form/CustomSelect'
import { formItemVariants } from '@cannect/new-components/atoms/FormController'
import { Control, FieldValues, Path } from 'react-hook-form'
import { VariantProps } from 'tailwind-variants'

type SelectFieldProps = Omit<CustomSelectProps, 'form'> & {
  label?: string
  description?: string
  isLoading?: boolean
} & VariantProps<typeof formItemVariants>

export const SelectField = <TFieldValues extends FieldValues>({
  control,
  name,
  isLoading = false,
  label,
  description,
  size: selectSize,
  variant: selectVariant,
  alignment: formItemAlignment,
  onSelectOption,
  ...props
}: SelectFieldProps & {
  name: Path<TFieldValues>
  control: Control<TFieldValues, any>
}) => {
  const LABEL_SIZES = {
    default: 'text-sm',
    small: 'text-sm',
    medium: 'text-lg'
  }

  return (
    <FormController.FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormController.FormItem alignment={formItemAlignment}>
            {label && (
              <FormController.FormLabel
                className={cn(`${selectSize ? LABEL_SIZES[selectSize] : 'text-sm'}`, 'text-neutral-500')}>
                {label}
              </FormController.FormLabel>
            )}
            <FormController.FormControl>
              <CustomSelect
                name={field.name}
                isInvalid={!!fieldState.error || !!fieldState.invalid}
                size={selectSize}
                variant={selectVariant}
                onBlur={field.onBlur}
                onChange={(e, meta) => {
                  field.onChange((e as TOption).value)
                  onSelectOption?.(e?.value, meta)
                }}
                value={(props.options?.find((option) => (option as TOption).value === field.value) as TOption) || null}
                isLoading={isLoading}
                {...props}
              />
            </FormController.FormControl>
            {description && <FormController.FormDescription>{description}</FormController.FormDescription>}
            <FormController.FormMessage />
          </FormController.FormItem>
        )
      }}
    />
  )
}

SelectField.displayName = 'SelectField'
